<template>
  <blank-layout>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <logo class="mx-auto max-h-40 w-auto" />
      <h2
        class="mt-6 text-center text-3xl leading-9 text-gray-900"
      >
        Reset Password
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 border border-gray-200">
        <a-alert
          v-if="error"
          type="error"
        >
          {{ error }}
        </a-alert>
        <div v-if="completed">
          <a-alert
            type="success"
            title="Password Reset!"
          >
            Your password has been successfully reset, click the button below to
            go back to the sign-in screen.
            <div class="mt-4">
              <div class="-mx-2 -my-1.5 flex">
                <a-button
                  variant
                  class="px-2 py-1.5 rounded-md text-sm leading-5 font-medium text-green-800 bg-green-100 hover:bg-green-200 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150"
                  @click="onSignIn"
                >
                  Sign In
                </a-button>
              </div>
            </div>
          </a-alert>
        </div>
        <div v-else>
          <a-form @submit="onSubmit">
            <div>
              <a-label
                for="password"
                :error="$v.password.$errors.length > 0"
              >
                New Password
              </a-label>
              <div class="mt-1 rounded-md shadow-sm">
                <a-input
                  id="password"
                  v-model="password"
                  type="password"
                  :readonly="loading"
                  required
                  :error="$v.password.$errors.length > 0"
                />
                <div v-if="$v.password.$errors.length > 0">
                  <a-help
                    error
                  >
                    Password must be at least 8 characters long.
                  </a-help>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <a-label
                for="confirm"
                :error="$v.confirm.$errors.length > 0 || confirmError"
              >
                Confirm Password
              </a-label>
              <div class="mt-1 rounded-md shadow-sm">
                <a-input
                  id="confirm"
                  v-model="confirm"
                  type="password"
                  :readonly="loading"
                  required
                  :error="$v.confirm.$errors.length > 0 || confirmError"
                />
                <div v-if="$v.confirm.$errors.length > 0 || confirmError">
                  <a-help error>
                    Password Confirmation Must Match.
                  </a-help>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <span class="block w-full rounded-md shadow-sm">
                <a-button
                  :loading="loading"
                  class="w-full"
                  @click="onSubmit"
                >Submit</a-button>
              </span>
            </div>
          </a-form>
        </div>
      </div>
    </div>
  </blank-layout>
</template>

<script>
import { isArray, join } from '@/utils/lodash';
import Logo from '@/components/layout/header/logo.vue';
import BlankLayout from '@/layouts/blank.vue';
import { mapActions, mapGetters } from 'vuex';
import { VuelidateMixin } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
export default {
  components: {
    Logo,
    BlankLayout
  },
  mixins: [VuelidateMixin],
  props: {
    token: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  data: () => ({
    password: '',
    confirm: '',
    loading: false,
    error: null,
    completed: false,
    confirmError: false
  }),
  validations: {
    password: { required, minLength: minLength(8) },
    confirm: {
      required,
      minLength: minLength(8)
      // Doesn't work yet
      // sameAs: sameAs('password')
    }
  },
  computed: {
    ...mapGetters('conferences', ['current'])
  },
  watch: {
    confirm() {
      this.confirmError = this.password != this.confirm;
    }
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    onSignIn() {
      this.$router.replace({
        name: 'dashboard',
        params: { conference: this.current }
      });
    },
    async onSubmit() {
      this.confirmError = false;
      this.$v.$touch();

      if (this.password != this.confirm) {
        this.confirmError = true;
      }

      if (this.$v.$error || this.confirmError) {
        return;
      }

      this.loading = true;
      this.error = null;

      try {
        await this.resetPassword({
          email: this.email,
          password: this.password,
          password_confirmation: this.confirm,
          token: this.token
        });

        this.completed = true;
      } catch ({
        response: {
          data: { errors }
        }
      }) {
        this.error = isArray(errors) ? join(errors, '<br />') : errors;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>